<template>
  <div class="products">
    <!-- /Header -->
    <div id="home" class="hero-area">
      <!-- Backgound Image -->
      <!-- <div class="bg-image bg-parallax overlay bgnet3" style="background-image:url(./img/architecture-blur-bridge-266044.jpg)"></div> -->
      <div class="bg-image bg-parallax123"
        :style="{ 'background-image': `url(${require('@/assets/images/about-slider.jpg')})` }">
      </div>
      <!-- /Backgound Image -->

      <div class="home-wrapper" data-animation="fadeInUp">
        <div class="container123 ct-one-plr">
          <div class="row">
            <div class="col-md-11">
              <div>
                <div id="owl-demo" class="owl-carousel owl-theme carousel-fade">
                  <div class="item">
                    <p class="h2 white-text">Products</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="about" class="section" style="padding-top:150px;">
      <!-- container -->
      <div class="container123 ct-one-plr">
        <div class="row opartners posr z9">
          <div class="col-md-12" data-animation="fadeInUp">
            <h2 class="mb30">
              OUR
              <span class="block">PRODUCTS</span>
            </h2>
            <p class="lead">
              Our mission at Sanus Health Care Private Ltd is to improve and extend the lives of people worldwide. 
              We are dedicated to developing innovative and effective therapies that address critical unmet medical needs and improve patient outcomes.
            </p>
            <p class="lead">Our products are the result of rigorous scientific research and development, and we are committed to maintaining the highest standards of quality and safety. 
              We work closely with healthcare providers, patients, and caregivers to ensure that our products are easy to use, effective, 
              and accessible to all who need them.</p>
            <p class="lead">At Sanus Health Care Private Ltd, we believe that every individual deserves access to quality healthcare, and we are passionate about improving healthcare outcomes for people across the globe. 
              We are committed to driving positive change in the healthcare industry, and our products reflect our unwavering dedication to this mission.</p>
            <p class="lead">Whether it is developing life-saving medications, researching new treatment options, or working to improve patient access to care, Sanus Health Care Private Ltd is committed to making a difference in the lives of people around the world.</p>
          </div>

          <template>
            <div class="product-page">
              <div class="product-item" v-for="product in products" :key="product.id">
                <img :src="product.image" alt="Product Image">
                <!-- <span class="product-title">{{ product.name }}</span> -->
              </div>
            </div>
          </template>



        </div>
      </div>
    </div>

    <div id="about" class="section">
      <!-- container -->
      <div class="container123 ct-one-plr">
        <div class="row opartners posr z9">
          <div class="col-md-8" data-animation="fadeInUp">
            <h2 class>
              OUR
              <span class="block">CULTURE</span>
            </h2>
            <div class="posr mt40">
              <p class="lead">
                A strong company culture is an essential part of any successful organization, including a pharmaceutical
                company. Here is a sample description of what the company culture of a pharma company might look like:</p>
              <p class="lead">

                Our pharma company is committed to fostering a positive and inclusive work environment that values
                diversity, collaboration, and innovation. We believe that by treating our employees with respect and
                providing them with the tools and resources they need to succeed, we can create a culture of excellence
                that benefits everyone.</p>
              <p class="lead">
                One of our core values is a commitment to scientific excellence. We encourage our employees to pursue
                scientific knowledge and professional development, and we support their efforts to stay up-to-date with
                the latest research and industry trends. We also prioritize ethical and responsible conduct in all of our
                business activities, to ensure that we maintain the highest standards of integrity and professionalism.
              </p>
              <p class="lead">
                We believe in fostering a culture of collaboration and teamwork. We encourage our employees to work
                together, share their expertise and ideas, and support each other's success. We also recognize the
                importance of work-life balance, and we strive to create a flexible and accommodating work environment
                that allows our employees to maintain a healthy work-life balance.</p>
              <p class="lead">
                Our commitment to diversity and inclusion is another important aspect of our company culture. We believe
                that diversity of thought, experience, and background is essential for driving innovation and excellence.
                We are committed to creating a workplace that is free from discrimination and harassment, and we strive to
                provide equal opportunities for all employees.</p>
              <p class="lead">
                Overall, our pharma company culture is one that prioritizes scientific excellence, collaboration, ethics,
                and inclusivity. We believe that by maintaining this culture, we can attract and retain the best talent,
                foster innovation, and achieve our mission of improving health and wellness for all.</p>

              <div class="posr mt50 block">
                <a class="rmore" href="/about">Read More</a>
              </div>
            </div>
          </div>
          <div class="col-md-4" data-animation="fadeInUp">
            <div class="row text-center">
              <div class="col-md-12 mt40">
                <p>
                  <img src="@/assets/images/our-culture.png" class="img-fluid" width="100%">
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section ptn" style="margin-bottom:-112px;">
      <div class="row">
        <div class="col-md-6 red-label-left dflex phn"
          style="    background-image: linear-gradient(to right, #0b5684, #1d8cbc);width:55vw;">
          <div class="mvauto label-box">
            <p class="mbn">WANT TO KNOW MORE ABOUT US</p>
            <a href="/about">
              <h1 class="mbn">About Us</h1>
            </a>
          </div>
        </div>
        <div class="col-md-6 red-label-right dflex phn"
          style="    background-image: linear-gradient(to right, #0b5684, #1d8cbc);width:55vw;">
          <div class="mvauto label-box right text-right">
            <p class="mbn">WANT TO GET TO KNOW US ?</p>
            <a href="/contact">
              <h1 class="mbn">Contact us</h1>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductPage',
  props: {
    productId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      products: [{
        id: 1,
        name: 'Rginine',
        image: '../img/products/Product_1.jpg',
        dosageForm: '',
        activeIngredients: '',
        strength: '',
        indications: '',
        dosageAndAdministration: '',
        warnings: ''
      },
      {
        id: 2,
        name: 'Iconmax',
        image: '../img/products/Product_2.jpg',
        dosageForm: '',
        activeIngredients: '',
        strength: '',
        indications: '',
        dosageAndAdministration: '',
        warnings: ''
      },
      {
        id: 3,
        name: 'Iconmax',
        image: '../img/products/Product_3.jpg',
        dosageForm: '',
        activeIngredients: '',
        strength: '',
        indications: '',
        dosageAndAdministration: '',
        warnings: ''
      },
      {
        id: 4,
        name: 'Iconmax',
        image: '../img/products/Product_4.jpg',
        dosageForm: '',
        activeIngredients: '',
        strength: '',
        indications: '',
        dosageAndAdministration: '',
        warnings: ''
      },
      {
        id: 5,
        name: 'Iconmax',
        image: '../img/products/Product_5.jpg',
        dosageForm: '',
        activeIngredients: '',
        strength: '',
        indications: '',
        dosageAndAdministration: '',
        warnings: ''
      },
      {
        id: 6,
        name: 'Iconmax',
        image: '../img/products/Product_6.jpg',
        dosageForm: '',
        activeIngredients: '',
        strength: '',
        indications: '',
        dosageAndAdministration: '',
        warnings: ''
      },
      {
        id: 7,
        name: 'Iconmax',
        image: '../img/products/Product_7.jpg',
        dosageForm: '',
        activeIngredients: '',
        strength: '',
        indications: '',
        dosageAndAdministration: '',
        warnings: ''
      },
      {
        id: 8,
        name: 'Iconmax',
        image: '../img/products/Product_8.jpg',
        dosageForm: '',
        activeIngredients: '',
        strength: '',
        indications: '',
        dosageAndAdministration: '',
        warnings: ''
      },
      {
        id: 10,
        name: 'Iconmax',
        image: '../img/products/Product_10.jpg',
        dosageForm: '',
        activeIngredients: '',
        strength: '',
        indications: '',
        dosageAndAdministration: '',
        warnings: ''
      },
      {
        id: 13,
        name: 'Iconmax',
        image: '../img/products/Product_13.jpg',
        dosageForm: '',
        activeIngredients: '',
        strength: '',
        indications: '',
        dosageAndAdministration: '',
        warnings: ''
      },
      {
        id: 14,
        name: 'Iconmax',
        image: '../img/products/Product_14.jpg',
        dosageForm: '',
        activeIngredients: '',
        strength: '',
        indications: '',
        dosageAndAdministration: '',
        warnings: ''
      }
      ]
    }
  }
}
</script>

<style scoped>
.product-page {
  display: flex;
  flex-flow: wrap;
  align-items: flex-start;
  padding: 20px;
  justify-content: center;
}

.product-page img {
  max-width: 350px;
  margin-bottom: 20px;
  padding: 10px;
  height: 200px;
}

.product-info {
  margin-bottom: 20px;
}

.product-info p {
  margin: 0;
}

.product-actions {
  display: flex;
  justify-content: center;
}

.product-item {}</style>
